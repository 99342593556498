import {
  APP_AUTH_LOADING,
  APP_USER,
  JWT_LOCAL_STORAGE,
} from '../config/constants';
import { AuthClaim } from '../interfaces/Props';

export const setAuthLoading = (type: 'init' | 'close') => {
  if (type === 'init') {
    localStorage.setItem(APP_AUTH_LOADING, JSON.stringify({ loading: true }));
  }

  if (type === 'close') {
    localStorage.removeItem(APP_AUTH_LOADING);
  }
};

export const isLoading = () => {
  if (isBrowser()) {
    const loading = window.localStorage.getItem(APP_AUTH_LOADING);

    if (loading !== null) {
      const loadingValue = JSON.parse(loading);
      if (loadingValue) return true;
    }
  }

  return false;
};

export const isBrowser = () => typeof window !== 'undefined';

export const getUser = (): AuthClaim => getLocalStorage('USER');

export const getToken = () => getLocalStorage('TOKEN');

export const setUser = (data: AuthClaim) =>
  setLocalStorage('USER', JSON.stringify(data));

export const setToken = (token: string) => setLocalStorage('TOKEN', token);

export const isLoggedIn = () => {
  const user = getUser();
  return user.id;
};

export const destroyStorage = () => {
  localStorage.removeItem(JWT_LOCAL_STORAGE);
  localStorage.removeItem(APP_USER);
};

const getLocalStorage = (type: 'TOKEN' | 'USER') => {
  if (isBrowser()) {
    const storageData = window.localStorage.getItem(APP_USER);
    if (storageData !== null) {
      try {
        if (type === 'TOKEN') {
          return storageData;
        }
        return JSON.parse(storageData);
      } catch (error) {
        return {};
      }
    }
  }

  if (type === 'TOKEN') return null;
  return {};
};

const setLocalStorage = (type: 'TOKEN' | 'USER', data: any) => {
  let ITEM_NAME = JWT_LOCAL_STORAGE;
  if (type === 'USER') ITEM_NAME = APP_USER;
  localStorage.setItem(ITEM_NAME, data);
};
