import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> &
  { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type ActionRegistration = {
  userId: Scalars['ID'];
  webinarId: Scalars['ID'];
  action: RegistrationActionEnum;
};

export type ActionWebinarInput = {
  id: Scalars['ID'];
  action?: Maybe<WebinarActionEnum>;
};

export type AddUserDetailsInput = {
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  sex?: Maybe<UserSexEnum>;
  region?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  barangay?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  industry?: Maybe<UserIndustryEnum>;
  age?: Maybe<Scalars['String']>;
  isPersonWithDisability?: Maybe<OptionEnum>;
  isIndigenous?: Maybe<OptionEnum>;
};

export type AddWebinarInput = {
  id?: Maybe<Scalars['ID']>;
  topic: Scalars['String'];
  speakerName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  passcode?: Maybe<Scalars['String']>;
  preTestUrl?: Maybe<Scalars['String']>;
  postTestUrl?: Maybe<Scalars['String']>;
  evaluationUrl?: Maybe<Scalars['String']>;
  facebookStreamUrl?: Maybe<Scalars['String']>;
  teamsMeetingUrl?: Maybe<Scalars['String']>;
  resourcesUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  platform?: Maybe<WebinarPlatformEnum>;
  type?: Maybe<WebinarTypeEnum>;
  industry?: Maybe<Array<Maybe<UserIndustryEnum>>>;
  createdBy?: Maybe<Scalars['String']>;
  isAllowRegistration?: Maybe<OptionEnum>;
  isShared?: Maybe<OptionEnum>;
  isWebinar?: Maybe<OptionEnum>;
};

export type Certificate = {
  __typename?: 'Certificate';
  certificateBase64?: Maybe<Scalars['String']>;
};

export type CertificateStatus = {
  __typename?: 'CertificateStatus';
  webinarTopic?: Maybe<Scalars['String']>;
  webinarDate?: Maybe<Scalars['String']>;
  webinarSpeaker?: Maybe<Scalars['String']>;
  webinarDescription?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  isValid?: Maybe<Scalars['Boolean']>;
};

export type DashboardMetrics = {
  __typename?: 'DashboardMetrics';
  numberOfWebinars: Scalars['Int'];
  numberOfUsers: Scalars['Int'];
  numberOfActivities: Scalars['Int'];
  numberOfCertificatesGenerated: Scalars['Int'];
  numberOfRegistrations: Scalars['Int'];
};

export type DashboardTopWebinar = {
  __typename?: 'DashboardTopWebinar';
  id: Scalars['String'];
  name: Scalars['String'];
  registrations: Scalars['String'];
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addRegistration?: Maybe<RegistrationResponse>;
  actionRegistration?: Maybe<RegistrationResponse>;
  takeRegistrationExam?: Maybe<RegistrationResponse>;
  authenticateUser?: Maybe<Scalars['ID']>;
  addUserDetails?: Maybe<Scalars['ID']>;
  updateUserRole?: Maybe<Scalars['ID']>;
  updateUser?: Maybe<Scalars['ID']>;
  deleteUser?: Maybe<Scalars['ID']>;
  disableUser?: Maybe<Scalars['ID']>;
  actionWebinar?: Maybe<WebinarActionReponse>;
  addWebinar?: Maybe<Scalars['ID']>;
  updateWebinar?: Maybe<Scalars['ID']>;
  deleteWebinar?: Maybe<Scalars['ID']>;
  uploadCertificate?: Maybe<File>;
};

export type MutationAddRegistrationArgs = {
  input?: Maybe<RegistrationInput>;
};

export type MutationActionRegistrationArgs = {
  input?: Maybe<ActionRegistration>;
};

export type MutationTakeRegistrationExamArgs = {
  input?: Maybe<TakeRegistrationExam>;
};

export type MutationAuthenticateUserArgs = {
  id: Scalars['ID'];
};

export type MutationAddUserDetailsArgs = {
  input: AddUserDetailsInput;
};

export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};

export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};

export type MutationDisableUserArgs = {
  input: UserDisableActionInput;
};

export type MutationActionWebinarArgs = {
  input?: Maybe<ActionWebinarInput>;
};

export type MutationAddWebinarArgs = {
  input?: Maybe<AddWebinarInput>;
};

export type MutationUpdateWebinarArgs = {
  input?: Maybe<UpdateWebinarInput>;
};

export type MutationDeleteWebinarArgs = {
  id: Scalars['ID'];
};

export type MutationUploadCertificateArgs = {
  webinarId: Scalars['ID'];
  file: Scalars['Upload'];
};

export enum OptionEnum {
  Yes = 'Yes',
  No = 'No',
}

export type Query = {
  __typename?: 'Query';
  verifyCertificate?: Maybe<CertificateStatus>;
  getCertificate?: Maybe<Certificate>;
  getMetrics: DashboardMetrics;
  getTopWebinars?: Maybe<Array<Maybe<DashboardTopWebinar>>>;
  getRegistration?: Maybe<RegistrationList>;
  getUser?: Maybe<User>;
  getUsers?: Maybe<UserList>;
  getWebinar?: Maybe<Webinar>;
  getWebinars?: Maybe<WebinarList>;
  getWebinarStats: WebinarMetric;
};

export type QueryVerifyCertificateArgs = {
  id: Scalars['ID'];
};

export type QueryGetCertificateArgs = {
  webinarId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type QueryGetRegistrationArgs = {
  input?: Maybe<RegistrationListInput>;
};

export type QueryGetUserArgs = {
  id: Scalars['ID'];
};

export type QueryGetUsersArgs = {
  query?: Maybe<UserQuery>;
};

export type QueryGetWebinarArgs = {
  id: Scalars['ID'];
  isWebinar?: Maybe<OptionEnum>;
};

export type QueryGetWebinarsArgs = {
  query?: Maybe<WebinarQuery>;
};

export enum RegistationStatusEnum {
  Pending = 'pending',
  Approved = 'approved',
  Denied = 'denied',
  Evaluation = 'evaluation',
  Finished = 'finished',
  Unknown = 'unknown',
}

export type Registration = {
  __typename?: 'Registration';
  id: Scalars['ID'];
  registrantId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  webinar?: Maybe<Webinar>;
  isPreTestFinished?: Maybe<Scalars['Boolean']>;
  isPostTestFinished?: Maybe<Scalars['Boolean']>;
  isEvaluationFinished?: Maybe<Scalars['Boolean']>;
  numberOfDownloads?: Maybe<Scalars['Int']>;
  lastDownload?: Maybe<Scalars['String']>;
  status?: Maybe<RegistationStatusEnum>;
};

export enum RegistrationActionEnum {
  Approve = 'approve',
  Cancel = 'cancel',
  Deny = 'deny',
  Release = 'release',
  Download = 'download',
}

export enum RegistrationExamTypeEnum {
  PreTest = 'preTest',
  PostTest = 'postTest',
  Evaluation = 'evaluation',
}

export type RegistrationInput = {
  userId?: Maybe<Scalars['ID']>;
  webinarId: Scalars['ID'];
};

export type RegistrationList = {
  __typename?: 'RegistrationList';
  pageCount?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Registration>>>;
};

export type RegistrationListInput = {
  search?: Maybe<Scalars['String']>;
  webinarId: Scalars['ID'];
  pageSize: Scalars['Int'];
  offset: Scalars['Int'];
  status: RegistationStatusEnum;
};

export type RegistrationResponse = {
  __typename?: 'RegistrationResponse';
  id: Scalars['ID'];
  registrationStatus?: Maybe<RegistationStatusEnum>;
};

export type TakeRegistrationExam = {
  webinarId: Scalars['ID'];
  examType: RegistrationExamTypeEnum;
};

export type UpdateUserInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sex?: Maybe<UserSexEnum>;
  region?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  barangay?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  industry?: Maybe<UserIndustryEnum>;
  age?: Maybe<Scalars['String']>;
  isPersonWithDisability?: Maybe<OptionEnum>;
  isIndigenous?: Maybe<OptionEnum>;
};

export type UpdateUserRoleInput = {
  id: Scalars['ID'];
  role: UserRoleEnum;
};

export type UpdateWebinarInput = {
  id: Scalars['ID'];
  topic: Scalars['String'];
  speakerName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  passcode?: Maybe<Scalars['String']>;
  preTestUrl?: Maybe<Scalars['String']>;
  postTestUrl?: Maybe<Scalars['String']>;
  evaluationUrl?: Maybe<Scalars['String']>;
  facebookStreamUrl?: Maybe<Scalars['String']>;
  teamsMeetingUrl?: Maybe<Scalars['String']>;
  resourcesUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  platform?: Maybe<WebinarPlatformEnum>;
  type?: Maybe<WebinarTypeEnum>;
  industry?: Maybe<Array<Maybe<UserIndustryEnum>>>;
  updatedBy?: Maybe<Scalars['String']>;
  isAllowRegistration?: Maybe<OptionEnum>;
  isShared?: Maybe<OptionEnum>;
  isWebinar?: Maybe<OptionEnum>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  name: Scalars['String'];
  sex?: Maybe<UserSexEnum>;
  certificateName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<UserRoleEnum>;
  region?: Maybe<Scalars['String']>;
  regionCode?: Maybe<Scalars['String']>;
  province?: Maybe<Scalars['String']>;
  provinceCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityCode?: Maybe<Scalars['String']>;
  barangay?: Maybe<Scalars['String']>;
  barangayCode?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  industry?: Maybe<UserIndustryEnum>;
  age?: Maybe<Scalars['String']>;
  isPersonWithDisability?: Maybe<OptionEnum>;
  isIndigenous?: Maybe<OptionEnum>;
  isCheckpoint?: Maybe<Scalars['Boolean']>;
  isDisabled?: Maybe<Scalars['Boolean']>;
};

export enum UserDisableActionEnum {
  Disable = 'DISABLE',
  Enable = 'ENABLE',
}

export type UserDisableActionInput = {
  id: Scalars['ID'];
  action: UserDisableActionEnum;
};

export enum UserIndustryEnum {
  Icieerd = 'ICIEERD',
  Cest = 'CEST',
  Academe = 'Academe',
  Employee = 'Employee',
  Food = 'Food',
  Lgu = 'LGU',
  Media = 'Media',
  Msme = 'MSME',
  Nga = 'NGA',
  Researcher = 'Researcher',
  Student = 'Student',
  Setup = 'SETUP',
  Other = 'Other',
}

export type UserList = {
  __typename?: 'UserList';
  pageCount?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<User>>>;
};

export type UserQuery = {
  search?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export enum UserRoleEnum {
  Administrator = 'Administrator',
  Organizer = 'Organizer',
  User = 'User',
}

export enum UserSexEnum {
  Male = 'Male',
  Female = 'Female',
}

export type Webinar = {
  __typename?: 'Webinar';
  id: Scalars['ID'];
  exams?: Maybe<WebinarExam>;
  topic: Scalars['String'];
  speakerName?: Maybe<Scalars['String']>;
  platform?: Maybe<WebinarPlatformEnum>;
  description?: Maybe<Scalars['String']>;
  facebookStreamUrl?: Maybe<Scalars['String']>;
  teamsMeetingUrl?: Maybe<Scalars['String']>;
  zoomMeetingId?: Maybe<Scalars['String']>;
  zoomMeetingPasscode?: Maybe<Scalars['String']>;
  zoomMeetingLink?: Maybe<Scalars['String']>;
  startUrl?: Maybe<Scalars['String']>;
  joinUrl?: Maybe<Scalars['String']>;
  resourcesUrl?: Maybe<Scalars['String']>;
  passcode?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  timezone?: Maybe<Scalars['String']>;
  status?: Maybe<WebinarStatusEnum>;
  registrationStatus?: Maybe<RegistationStatusEnum>;
  createdAt?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['String']>;
  registrations?: Maybe<Array<Maybe<User>>>;
  isRegistered?: Maybe<Scalars['Boolean']>;
  type?: Maybe<WebinarTypeEnum>;
  industry?: Maybe<Array<Maybe<UserIndustryEnum>>>;
  certificatePath?: Maybe<Scalars['String']>;
  certificateBase64?: Maybe<Scalars['String']>;
  isAllowRegistration?: Maybe<OptionEnum>;
  isShared?: Maybe<OptionEnum>;
  isWebinar?: Maybe<OptionEnum>;
};

export enum WebinarActionEnum {
  Stop = 'stop',
  Start = 'start',
  End = 'end',
}

export type WebinarActionReponse = {
  __typename?: 'WebinarActionReponse';
  id: Scalars['ID'];
  status: WebinarStatusEnum;
};

export type WebinarExam = {
  __typename?: 'WebinarExam';
  preTestUrl?: Maybe<Scalars['String']>;
  postTestUrl?: Maybe<Scalars['String']>;
  evaluationUrl?: Maybe<Scalars['String']>;
};

export type WebinarList = {
  __typename?: 'WebinarList';
  pageCount?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalRecords?: Maybe<Scalars['Int']>;
  data?: Maybe<Array<Maybe<Webinar>>>;
};

export type WebinarMetric = {
  __typename?: 'WebinarMetric';
  totalCount: Scalars['Int'];
  liveCount: Scalars['Int'];
  reservedCount: Scalars['Int'];
  joinedCount: Scalars['Int'];
  recommendedCount: Scalars['Int'];
  upcomingCount: Scalars['Int'];
  finishedCount: Scalars['Int'];
};

export enum WebinarPlatformEnum {
  Zoom = 'Zoom',
  Teams = 'Teams',
  Facebook = 'Facebook',
}

export type WebinarQuery = {
  reserved?: Maybe<OptionEnum>;
  joined?: Maybe<OptionEnum>;
  recommended?: Maybe<OptionEnum>;
  webinar?: Maybe<OptionEnum>;
  search?: Maybe<Scalars['String']>;
  pageSize?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  status?: Maybe<WebinarStatusEnum>;
  filterType?: Maybe<WebinarTypeEnum>;
};

export enum WebinarStatusEnum {
  Waiting = 'waiting',
  Started = 'started',
  Finished = 'finished',
}

export enum WebinarTypeEnum {
  Technology = 'Technology',
  Innovation = 'Innovation',
  Communication = 'Communication',
  Entrepreneurship = 'Entrepreneurship',
  Food = 'Food',
  Media = 'Media',
  CivilServiceEligibility = 'CivilServiceEligibility',
  ScientificCareerSystem = 'ScientificCareerSystem',
  GenderDevelopment = 'GenderDevelopment',
  DesignThinkingTeachers = 'DesignThinkingTeachers',
  InventionContestExhibits = 'InventionContestExhibits',
  ScienceTechnology = 'ScienceTechnology',
  MassMetrologyCalibration = 'MassMetrologyCalibration',
  Management = 'Management',
  WasteManagement = 'WasteManagement',
  SafetyHealth = 'SafetyHealth',
  EnergyConservation = 'EnergyConservation',
  BusinessManagement = 'BusinessManagement',
  ResearchDevelopment = 'ResearchDevelopment',
  DisasterManagement = 'DisasterManagement',
  StarBooks = 'StarBooks',
  GeneralActivities = 'GeneralActivities',
}

export type DashboardMetricsQueryVariables = Exact<{ [key: string]: never }>;

export type DashboardMetricsQuery = {
  __typename?: 'Query';
  getMetrics: {
    __typename?: 'DashboardMetrics';
    numberOfWebinars: number;
    numberOfUsers: number;
    numberOfActivities: number;
    numberOfCertificatesGenerated: number;
    numberOfRegistrations: number;
  };
};

export type DashboardTopWebinarsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type DashboardTopWebinarsQuery = {
  __typename?: 'Query';
  getTopWebinars?: Maybe<
    Array<
      Maybe<{
        __typename?: 'DashboardTopWebinar';
        id: string;
        name: string;
        registrations: string;
      }>
    >
  >;
};

export type AddRegistrationMutationVariables = Exact<{
  addRegistrationInput?: Maybe<RegistrationInput>;
}>;

export type AddRegistrationMutation = {
  __typename?: 'Mutation';
  addRegistration?: Maybe<{
    __typename?: 'RegistrationResponse';
    id: string;
    registrationStatus?: Maybe<RegistationStatusEnum>;
  }>;
};

export type ActionRegistrationMutationVariables = Exact<{
  actionRegistrationInput?: Maybe<ActionRegistration>;
}>;

export type ActionRegistrationMutation = {
  __typename?: 'Mutation';
  actionRegistration?: Maybe<{
    __typename?: 'RegistrationResponse';
    id: string;
    registrationStatus?: Maybe<RegistationStatusEnum>;
  }>;
};

export type TakeRegistrationExamMutationVariables = Exact<{
  takeRegistrationExamInput?: Maybe<TakeRegistrationExam>;
}>;

export type TakeRegistrationExamMutation = {
  __typename?: 'Mutation';
  takeRegistrationExam?: Maybe<{
    __typename?: 'RegistrationResponse';
    id: string;
  }>;
};

export type RegistrationListQueryVariables = Exact<{
  getRegistrationInput?: Maybe<RegistrationListInput>;
}>;

export type RegistrationListQuery = {
  __typename?: 'Query';
  getRegistration?: Maybe<{
    __typename?: 'RegistrationList';
    pageCount?: Maybe<number>;
    offset?: Maybe<number>;
    pageSize?: Maybe<number>;
    totalRecords?: Maybe<number>;
    data?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Registration';
          registrantId?: Maybe<string>;
          email?: Maybe<string>;
          isPreTestFinished?: Maybe<boolean>;
          isPostTestFinished?: Maybe<boolean>;
          isEvaluationFinished?: Maybe<boolean>;
          numberOfDownloads?: Maybe<number>;
          lastDownload?: Maybe<string>;
          status?: Maybe<RegistationStatusEnum>;
          user?: Maybe<{
            __typename?: 'User';
            id: string;
            name: string;
            organization?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
};

export type AuthenticateUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type AuthenticateUserMutation = {
  __typename?: 'Mutation';
  authenticateUser?: Maybe<string>;
};

export type UpdateUserMutationVariables = Exact<{
  updateUserInput: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: Maybe<string>;
};

export type UpdateUserRoleMutationVariables = Exact<{
  updateUserRoleInput: UpdateUserRoleInput;
}>;

export type UpdateUserRoleMutation = {
  __typename?: 'Mutation';
  updateUserRole?: Maybe<string>;
};

export type AddUserDetailsMutationVariables = Exact<{
  addUserDetailsInput: AddUserDetailsInput;
}>;

export type AddUserDetailsMutation = {
  __typename?: 'Mutation';
  addUserDetails?: Maybe<string>;
};

export type GetUsersQueryVariables = Exact<{
  getUsersQuery?: Maybe<UserQuery>;
}>;

export type GetUsersQuery = {
  __typename?: 'Query';
  getUsers?: Maybe<{
    __typename?: 'UserList';
    pageCount?: Maybe<number>;
    offset?: Maybe<number>;
    pageSize?: Maybe<number>;
    totalRecords?: Maybe<number>;
    data?: Maybe<
      Array<
        Maybe<{
          __typename?: 'User';
          id: string;
          name: string;
          email?: Maybe<string>;
          role?: Maybe<UserRoleEnum>;
          region?: Maybe<string>;
          province?: Maybe<string>;
          city?: Maybe<string>;
          barangay?: Maybe<string>;
          organization?: Maybe<string>;
          industry?: Maybe<UserIndustryEnum>;
          jobTitle?: Maybe<string>;
          isCheckpoint?: Maybe<boolean>;
          isDisabled?: Maybe<boolean>;
        }>
      >
    >;
  }>;
};

export type GetUserQueryVariables = Exact<{
  getUserId: Scalars['ID'];
}>;

export type GetUserQuery = {
  __typename?: 'Query';
  getUser?: Maybe<{
    __typename?: 'User';
    id: string;
    name: string;
    sex?: Maybe<UserSexEnum>;
    certificateName?: Maybe<string>;
    email?: Maybe<string>;
    role?: Maybe<UserRoleEnum>;
    region?: Maybe<string>;
    regionCode?: Maybe<string>;
    province?: Maybe<string>;
    provinceCode?: Maybe<string>;
    city?: Maybe<string>;
    cityCode?: Maybe<string>;
    barangay?: Maybe<string>;
    barangayCode?: Maybe<string>;
    organization?: Maybe<string>;
    industry?: Maybe<UserIndustryEnum>;
    jobTitle?: Maybe<string>;
    age?: Maybe<string>;
    isPersonWithDisability?: Maybe<OptionEnum>;
    isIndigenous?: Maybe<OptionEnum>;
    isCheckpoint?: Maybe<boolean>;
    isDisabled?: Maybe<boolean>;
  }>;
};

export type GetCertificateQueryVariables = Exact<{
  getCertificateWebinarId: Scalars['ID'];
  getCertificateUserId: Scalars['ID'];
}>;

export type GetCertificateQuery = {
  __typename?: 'Query';
  getCertificate?: Maybe<{
    __typename?: 'Certificate';
    certificateBase64?: Maybe<string>;
  }>;
};

export type VerifyCertificateQueryVariables = Exact<{
  verifyCertificateId: Scalars['ID'];
}>;

export type VerifyCertificateQuery = {
  __typename?: 'Query';
  verifyCertificate?: Maybe<{
    __typename?: 'CertificateStatus';
    isValid?: Maybe<boolean>;
    webinarTopic?: Maybe<string>;
    webinarDate?: Maybe<string>;
    webinarSpeaker?: Maybe<string>;
    webinarDescription?: Maybe<string>;
    userName?: Maybe<string>;
  }>;
};

export type UploadCertificateMutationVariables = Exact<{
  uploadCertificateWebinarId: Scalars['ID'];
  uploadCertificateFile: Scalars['Upload'];
}>;

export type UploadCertificateMutation = {
  __typename?: 'Mutation';
  uploadCertificate?: Maybe<{
    __typename?: 'File';
    filename: string;
    mimetype: string;
    encoding: string;
  }>;
};

export type GetWebinarStatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetWebinarStatsQuery = {
  __typename?: 'Query';
  getWebinarStats: {
    __typename?: 'WebinarMetric';
    totalCount: number;
    liveCount: number;
    reservedCount: number;
    joinedCount: number;
    recommendedCount: number;
    upcomingCount: number;
    finishedCount: number;
  };
};

export type GetAllWebinarsQueryVariables = Exact<{
  getWebinarsQuery?: Maybe<WebinarQuery>;
}>;

export type GetAllWebinarsQuery = {
  __typename?: 'Query';
  getWebinars?: Maybe<{
    __typename?: 'WebinarList';
    pageCount?: Maybe<number>;
    offset?: Maybe<number>;
    pageSize?: Maybe<number>;
    totalRecords?: Maybe<number>;
    data?: Maybe<
      Array<
        Maybe<{
          __typename?: 'Webinar';
          id: string;
          topic: string;
          startTime?: Maybe<string>;
          type?: Maybe<WebinarTypeEnum>;
          exams?: Maybe<{
            __typename?: 'WebinarExam';
            preTestUrl?: Maybe<string>;
            postTestUrl?: Maybe<string>;
            evaluationUrl?: Maybe<string>;
          }>;
        }>
      >
    >;
  }>;
};

export type GetWebinarQueryVariables = Exact<{
  getWebinarId: Scalars['ID'];
  getIsWebinar?: Maybe<OptionEnum>;
}>;

export type GetWebinarQuery = {
  __typename?: 'Query';
  getWebinar?: Maybe<{
    __typename?: 'Webinar';
    id: string;
    topic: string;
    description?: Maybe<string>;
    startUrl?: Maybe<string>;
    joinUrl?: Maybe<string>;
    passcode?: Maybe<string>;
    startTime?: Maybe<string>;
    speakerName?: Maybe<string>;
    platform?: Maybe<WebinarPlatformEnum>;
    duration?: Maybe<number>;
    status?: Maybe<WebinarStatusEnum>;
    registrationStatus?: Maybe<RegistationStatusEnum>;
    createdAt?: Maybe<string>;
    createdBy?: Maybe<string>;
    isRegistered?: Maybe<boolean>;
    facebookStreamUrl?: Maybe<string>;
    teamsMeetingUrl?: Maybe<string>;
    resourcesUrl?: Maybe<string>;
    certificateBase64?: Maybe<string>;
    zoomMeetingId?: Maybe<string>;
    zoomMeetingLink?: Maybe<string>;
    industry?: Maybe<Array<Maybe<UserIndustryEnum>>>;
    isAllowRegistration?: Maybe<OptionEnum>;
    isShared?: Maybe<OptionEnum>;
    isWebinar?: Maybe<OptionEnum>;
    type?: Maybe<WebinarTypeEnum>;
    exams?: Maybe<{
      __typename?: 'WebinarExam';
      preTestUrl?: Maybe<string>;
      postTestUrl?: Maybe<string>;
      evaluationUrl?: Maybe<string>;
    }>;
    registrations?: Maybe<
      Array<
        Maybe<{
          __typename?: 'User';
          id: string;
          name: string;
          email?: Maybe<string>;
          organization?: Maybe<string>;
        }>
      >
    >;
  }>;
};

export type ActionWebinarMutationVariables = Exact<{
  actionWebinarInput?: Maybe<ActionWebinarInput>;
}>;

export type ActionWebinarMutation = {
  __typename?: 'Mutation';
  actionWebinar?: Maybe<{
    __typename?: 'WebinarActionReponse';
    id: string;
    status: WebinarStatusEnum;
  }>;
};

export type UpdateWebinarMutationVariables = Exact<{
  updateWebinarInput?: Maybe<UpdateWebinarInput>;
}>;

export type UpdateWebinarMutation = {
  __typename?: 'Mutation';
  updateWebinar?: Maybe<string>;
};

export type AddWebinarMutationVariables = Exact<{
  addWebinarInput?: Maybe<AddWebinarInput>;
}>;

export type AddWebinarMutation = {
  __typename?: 'Mutation';
  addWebinar?: Maybe<string>;
};

export type UpdateUserRoleMutationMutationVariables = Exact<{
  updateUserRoleInput: UpdateUserRoleInput;
}>;

export type UpdateUserRoleMutationMutation = {
  __typename?: 'Mutation';
  updateUserRole?: Maybe<string>;
};

export type DeleteWebinarMutationVariables = Exact<{
  deleteWebinarId: Scalars['ID'];
}>;

export type DeleteWebinarMutation = {
  __typename?: 'Mutation';
  deleteWebinar?: Maybe<string>;
};

export const DashboardMetricsDocument = gql`
  query DashboardMetrics {
    getMetrics {
      numberOfWebinars
      numberOfUsers
      numberOfActivities
      numberOfCertificatesGenerated
      numberOfRegistrations
    }
  }
`;

/**
 * __useDashboardMetricsQuery__
 *
 * To run a query within a React component, call `useDashboardMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardMetricsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardMetricsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardMetricsQuery,
    DashboardMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DashboardMetricsQuery, DashboardMetricsQueryVariables>(
    DashboardMetricsDocument,
    options,
  );
}
export function useDashboardMetricsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardMetricsQuery,
    DashboardMetricsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardMetricsQuery,
    DashboardMetricsQueryVariables
  >(DashboardMetricsDocument, options);
}
export type DashboardMetricsQueryHookResult = ReturnType<
  typeof useDashboardMetricsQuery
>;
export type DashboardMetricsLazyQueryHookResult = ReturnType<
  typeof useDashboardMetricsLazyQuery
>;
export type DashboardMetricsQueryResult = Apollo.QueryResult<
  DashboardMetricsQuery,
  DashboardMetricsQueryVariables
>;
export const DashboardTopWebinarsDocument = gql`
  query DashboardTopWebinars {
    getTopWebinars {
      id
      name
      registrations
    }
  }
`;

/**
 * __useDashboardTopWebinarsQuery__
 *
 * To run a query within a React component, call `useDashboardTopWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardTopWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardTopWebinarsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDashboardTopWebinarsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    DashboardTopWebinarsQuery,
    DashboardTopWebinarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    DashboardTopWebinarsQuery,
    DashboardTopWebinarsQueryVariables
  >(DashboardTopWebinarsDocument, options);
}
export function useDashboardTopWebinarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    DashboardTopWebinarsQuery,
    DashboardTopWebinarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    DashboardTopWebinarsQuery,
    DashboardTopWebinarsQueryVariables
  >(DashboardTopWebinarsDocument, options);
}
export type DashboardTopWebinarsQueryHookResult = ReturnType<
  typeof useDashboardTopWebinarsQuery
>;
export type DashboardTopWebinarsLazyQueryHookResult = ReturnType<
  typeof useDashboardTopWebinarsLazyQuery
>;
export type DashboardTopWebinarsQueryResult = Apollo.QueryResult<
  DashboardTopWebinarsQuery,
  DashboardTopWebinarsQueryVariables
>;
export const AddRegistrationDocument = gql`
  mutation AddRegistration($addRegistrationInput: RegistrationInput) {
    addRegistration(input: $addRegistrationInput) {
      id
      registrationStatus
    }
  }
`;
export type AddRegistrationMutationFn = Apollo.MutationFunction<
  AddRegistrationMutation,
  AddRegistrationMutationVariables
>;

/**
 * __useAddRegistrationMutation__
 *
 * To run a mutation, you first call `useAddRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRegistrationMutation, { data, loading, error }] = useAddRegistrationMutation({
 *   variables: {
 *      addRegistrationInput: // value for 'addRegistrationInput'
 *   },
 * });
 */
export function useAddRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddRegistrationMutation,
    AddRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddRegistrationMutation,
    AddRegistrationMutationVariables
  >(AddRegistrationDocument, options);
}
export type AddRegistrationMutationHookResult = ReturnType<
  typeof useAddRegistrationMutation
>;
export type AddRegistrationMutationResult =
  Apollo.MutationResult<AddRegistrationMutation>;
export type AddRegistrationMutationOptions = Apollo.BaseMutationOptions<
  AddRegistrationMutation,
  AddRegistrationMutationVariables
>;
export const ActionRegistrationDocument = gql`
  mutation ActionRegistration($actionRegistrationInput: ActionRegistration) {
    actionRegistration(input: $actionRegistrationInput) {
      id
      registrationStatus
    }
  }
`;
export type ActionRegistrationMutationFn = Apollo.MutationFunction<
  ActionRegistrationMutation,
  ActionRegistrationMutationVariables
>;

/**
 * __useActionRegistrationMutation__
 *
 * To run a mutation, you first call `useActionRegistrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionRegistrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionRegistrationMutation, { data, loading, error }] = useActionRegistrationMutation({
 *   variables: {
 *      actionRegistrationInput: // value for 'actionRegistrationInput'
 *   },
 * });
 */
export function useActionRegistrationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionRegistrationMutation,
    ActionRegistrationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionRegistrationMutation,
    ActionRegistrationMutationVariables
  >(ActionRegistrationDocument, options);
}
export type ActionRegistrationMutationHookResult = ReturnType<
  typeof useActionRegistrationMutation
>;
export type ActionRegistrationMutationResult =
  Apollo.MutationResult<ActionRegistrationMutation>;
export type ActionRegistrationMutationOptions = Apollo.BaseMutationOptions<
  ActionRegistrationMutation,
  ActionRegistrationMutationVariables
>;
export const TakeRegistrationExamDocument = gql`
  mutation TakeRegistrationExam(
    $takeRegistrationExamInput: TakeRegistrationExam
  ) {
    takeRegistrationExam(input: $takeRegistrationExamInput) {
      id
    }
  }
`;
export type TakeRegistrationExamMutationFn = Apollo.MutationFunction<
  TakeRegistrationExamMutation,
  TakeRegistrationExamMutationVariables
>;

/**
 * __useTakeRegistrationExamMutation__
 *
 * To run a mutation, you first call `useTakeRegistrationExamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTakeRegistrationExamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [takeRegistrationExamMutation, { data, loading, error }] = useTakeRegistrationExamMutation({
 *   variables: {
 *      takeRegistrationExamInput: // value for 'takeRegistrationExamInput'
 *   },
 * });
 */
export function useTakeRegistrationExamMutation(
  baseOptions?: Apollo.MutationHookOptions<
    TakeRegistrationExamMutation,
    TakeRegistrationExamMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    TakeRegistrationExamMutation,
    TakeRegistrationExamMutationVariables
  >(TakeRegistrationExamDocument, options);
}
export type TakeRegistrationExamMutationHookResult = ReturnType<
  typeof useTakeRegistrationExamMutation
>;
export type TakeRegistrationExamMutationResult =
  Apollo.MutationResult<TakeRegistrationExamMutation>;
export type TakeRegistrationExamMutationOptions = Apollo.BaseMutationOptions<
  TakeRegistrationExamMutation,
  TakeRegistrationExamMutationVariables
>;
export const RegistrationListDocument = gql`
  query RegistrationList($getRegistrationInput: RegistrationListInput) {
    getRegistration(input: $getRegistrationInput) {
      pageCount
      offset
      pageSize
      totalRecords
      data {
        registrantId
        email
        user {
          id
          name
          organization
        }
        isPreTestFinished
        isPostTestFinished
        isEvaluationFinished
        numberOfDownloads
        lastDownload
        status
      }
    }
  }
`;

/**
 * __useRegistrationListQuery__
 *
 * To run a query within a React component, call `useRegistrationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistrationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistrationListQuery({
 *   variables: {
 *      getRegistrationInput: // value for 'getRegistrationInput'
 *   },
 * });
 */
export function useRegistrationListQuery(
  baseOptions?: Apollo.QueryHookOptions<
    RegistrationListQuery,
    RegistrationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegistrationListQuery, RegistrationListQueryVariables>(
    RegistrationListDocument,
    options,
  );
}
export function useRegistrationListLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    RegistrationListQuery,
    RegistrationListQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    RegistrationListQuery,
    RegistrationListQueryVariables
  >(RegistrationListDocument, options);
}
export type RegistrationListQueryHookResult = ReturnType<
  typeof useRegistrationListQuery
>;
export type RegistrationListLazyQueryHookResult = ReturnType<
  typeof useRegistrationListLazyQuery
>;
export type RegistrationListQueryResult = Apollo.QueryResult<
  RegistrationListQuery,
  RegistrationListQueryVariables
>;
export const AuthenticateUserDocument = gql`
  mutation AuthenticateUser($id: ID!) {
    authenticateUser(id: $id)
  }
`;
export type AuthenticateUserMutationFn = Apollo.MutationFunction<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;

/**
 * __useAuthenticateUserMutation__
 *
 * To run a mutation, you first call `useAuthenticateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthenticateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authenticateUserMutation, { data, loading, error }] = useAuthenticateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useAuthenticateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AuthenticateUserMutation,
    AuthenticateUserMutationVariables
  >(AuthenticateUserDocument, options);
}
export type AuthenticateUserMutationHookResult = ReturnType<
  typeof useAuthenticateUserMutation
>;
export type AuthenticateUserMutationResult =
  Apollo.MutationResult<AuthenticateUserMutation>;
export type AuthenticateUserMutationOptions = Apollo.BaseMutationOptions<
  AuthenticateUserMutation,
  AuthenticateUserMutationVariables
>;
export const UpdateUserDocument = gql`
  mutation UpdateUser($updateUserInput: UpdateUserInput!) {
    updateUser(input: $updateUserInput)
  }
`;
export type UpdateUserMutationFn = Apollo.MutationFunction<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updateUserInput: // value for 'updateUserInput'
 *   },
 * });
 */
export function useUpdateUserMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserMutation,
    UpdateUserMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(
    UpdateUserDocument,
    options,
  );
}
export type UpdateUserMutationHookResult = ReturnType<
  typeof useUpdateUserMutation
>;
export type UpdateUserMutationResult =
  Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserMutation,
  UpdateUserMutationVariables
>;
export const UpdateUserRoleDocument = gql`
  mutation UpdateUserRole($updateUserRoleInput: UpdateUserRoleInput!) {
    updateUserRole(input: $updateUserRoleInput)
  }
`;
export type UpdateUserRoleMutationFn = Apollo.MutationFunction<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      updateUserRoleInput: // value for 'updateUserRoleInput'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserRoleMutation,
    UpdateUserRoleMutationVariables
  >(UpdateUserRoleDocument, options);
}
export type UpdateUserRoleMutationHookResult = ReturnType<
  typeof useUpdateUserRoleMutation
>;
export type UpdateUserRoleMutationResult =
  Apollo.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables
>;
export const AddUserDetailsDocument = gql`
  mutation AddUserDetails($addUserDetailsInput: AddUserDetailsInput!) {
    addUserDetails(input: $addUserDetailsInput)
  }
`;
export type AddUserDetailsMutationFn = Apollo.MutationFunction<
  AddUserDetailsMutation,
  AddUserDetailsMutationVariables
>;

/**
 * __useAddUserDetailsMutation__
 *
 * To run a mutation, you first call `useAddUserDetailsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserDetailsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserDetailsMutation, { data, loading, error }] = useAddUserDetailsMutation({
 *   variables: {
 *      addUserDetailsInput: // value for 'addUserDetailsInput'
 *   },
 * });
 */
export function useAddUserDetailsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddUserDetailsMutation,
    AddUserDetailsMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddUserDetailsMutation,
    AddUserDetailsMutationVariables
  >(AddUserDetailsDocument, options);
}
export type AddUserDetailsMutationHookResult = ReturnType<
  typeof useAddUserDetailsMutation
>;
export type AddUserDetailsMutationResult =
  Apollo.MutationResult<AddUserDetailsMutation>;
export type AddUserDetailsMutationOptions = Apollo.BaseMutationOptions<
  AddUserDetailsMutation,
  AddUserDetailsMutationVariables
>;
export const GetUsersDocument = gql`
  query GetUsers($getUsersQuery: UserQuery) {
    getUsers(query: $getUsersQuery) {
      pageCount
      offset
      pageSize
      totalRecords
      data {
        id
        name
        email
        role
        region
        province
        city
        barangay
        organization
        industry
        jobTitle
        isCheckpoint
        isDisabled
      }
    }
  }
`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      getUsersQuery: // value for 'getUsersQuery'
 *   },
 * });
 */
export function useGetUsersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export function useGetUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersQuery,
    GetUsersQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(
    GetUsersDocument,
    options,
  );
}
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<
  typeof useGetUsersLazyQuery
>;
export type GetUsersQueryResult = Apollo.QueryResult<
  GetUsersQuery,
  GetUsersQueryVariables
>;
export const GetUserDocument = gql`
  query GetUser($getUserId: ID!) {
    getUser(id: $getUserId) {
      id
      name
      sex
      certificateName
      email
      role
      region
      regionCode
      province
      provinceCode
      city
      cityCode
      barangay
      barangayCode
      organization
      industry
      jobTitle
      age
      isPersonWithDisability
      isIndigenous
      isCheckpoint
      isDisabled
    }
  }
`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      getUserId: // value for 'getUserId'
 *   },
 * });
 */
export function useGetUserQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export function useGetUserLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUserQuery,
    GetUserQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(
    GetUserDocument,
    options,
  );
}
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<
  GetUserQuery,
  GetUserQueryVariables
>;
export const GetCertificateDocument = gql`
  query GetCertificate(
    $getCertificateWebinarId: ID!
    $getCertificateUserId: ID!
  ) {
    getCertificate(
      webinarId: $getCertificateWebinarId
      userId: $getCertificateUserId
    ) {
      certificateBase64
    }
  }
`;

/**
 * __useGetCertificateQuery__
 *
 * To run a query within a React component, call `useGetCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCertificateQuery({
 *   variables: {
 *      getCertificateWebinarId: // value for 'getCertificateWebinarId'
 *      getCertificateUserId: // value for 'getCertificateUserId'
 *   },
 * });
 */
export function useGetCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCertificateQuery,
    GetCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options,
  );
}
export function useGetCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCertificateQuery,
    GetCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCertificateQuery, GetCertificateQueryVariables>(
    GetCertificateDocument,
    options,
  );
}
export type GetCertificateQueryHookResult = ReturnType<
  typeof useGetCertificateQuery
>;
export type GetCertificateLazyQueryHookResult = ReturnType<
  typeof useGetCertificateLazyQuery
>;
export type GetCertificateQueryResult = Apollo.QueryResult<
  GetCertificateQuery,
  GetCertificateQueryVariables
>;
export const VerifyCertificateDocument = gql`
  query VerifyCertificate($verifyCertificateId: ID!) {
    verifyCertificate(id: $verifyCertificateId) {
      isValid
      webinarTopic
      webinarDate
      webinarSpeaker
      webinarDescription
      userName
    }
  }
`;

/**
 * __useVerifyCertificateQuery__
 *
 * To run a query within a React component, call `useVerifyCertificateQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyCertificateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyCertificateQuery({
 *   variables: {
 *      verifyCertificateId: // value for 'verifyCertificateId'
 *   },
 * });
 */
export function useVerifyCertificateQuery(
  baseOptions: Apollo.QueryHookOptions<
    VerifyCertificateQuery,
    VerifyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    VerifyCertificateQuery,
    VerifyCertificateQueryVariables
  >(VerifyCertificateDocument, options);
}
export function useVerifyCertificateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    VerifyCertificateQuery,
    VerifyCertificateQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    VerifyCertificateQuery,
    VerifyCertificateQueryVariables
  >(VerifyCertificateDocument, options);
}
export type VerifyCertificateQueryHookResult = ReturnType<
  typeof useVerifyCertificateQuery
>;
export type VerifyCertificateLazyQueryHookResult = ReturnType<
  typeof useVerifyCertificateLazyQuery
>;
export type VerifyCertificateQueryResult = Apollo.QueryResult<
  VerifyCertificateQuery,
  VerifyCertificateQueryVariables
>;
export const UploadCertificateDocument = gql`
  mutation UploadCertificate(
    $uploadCertificateWebinarId: ID!
    $uploadCertificateFile: Upload!
  ) {
    uploadCertificate(
      webinarId: $uploadCertificateWebinarId
      file: $uploadCertificateFile
    ) {
      filename
      mimetype
      encoding
    }
  }
`;
export type UploadCertificateMutationFn = Apollo.MutationFunction<
  UploadCertificateMutation,
  UploadCertificateMutationVariables
>;

/**
 * __useUploadCertificateMutation__
 *
 * To run a mutation, you first call `useUploadCertificateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadCertificateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadCertificateMutation, { data, loading, error }] = useUploadCertificateMutation({
 *   variables: {
 *      uploadCertificateWebinarId: // value for 'uploadCertificateWebinarId'
 *      uploadCertificateFile: // value for 'uploadCertificateFile'
 *   },
 * });
 */
export function useUploadCertificateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UploadCertificateMutation,
    UploadCertificateMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UploadCertificateMutation,
    UploadCertificateMutationVariables
  >(UploadCertificateDocument, options);
}
export type UploadCertificateMutationHookResult = ReturnType<
  typeof useUploadCertificateMutation
>;
export type UploadCertificateMutationResult =
  Apollo.MutationResult<UploadCertificateMutation>;
export type UploadCertificateMutationOptions = Apollo.BaseMutationOptions<
  UploadCertificateMutation,
  UploadCertificateMutationVariables
>;
export const GetWebinarStatsDocument = gql`
  query GetWebinarStats {
    getWebinarStats {
      totalCount
      liveCount
      reservedCount
      joinedCount
      recommendedCount
      upcomingCount
      finishedCount
    }
  }
`;

/**
 * __useGetWebinarStatsQuery__
 *
 * To run a query within a React component, call `useGetWebinarStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebinarStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebinarStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWebinarStatsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWebinarStatsQuery,
    GetWebinarStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebinarStatsQuery, GetWebinarStatsQueryVariables>(
    GetWebinarStatsDocument,
    options,
  );
}
export function useGetWebinarStatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWebinarStatsQuery,
    GetWebinarStatsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWebinarStatsQuery,
    GetWebinarStatsQueryVariables
  >(GetWebinarStatsDocument, options);
}
export type GetWebinarStatsQueryHookResult = ReturnType<
  typeof useGetWebinarStatsQuery
>;
export type GetWebinarStatsLazyQueryHookResult = ReturnType<
  typeof useGetWebinarStatsLazyQuery
>;
export type GetWebinarStatsQueryResult = Apollo.QueryResult<
  GetWebinarStatsQuery,
  GetWebinarStatsQueryVariables
>;
export const GetAllWebinarsDocument = gql`
  query GetAllWebinars($getWebinarsQuery: WebinarQuery) {
    getWebinars(query: $getWebinarsQuery) {
      pageCount
      offset
      pageSize
      totalRecords
      data {
        id
        topic
        startTime
        exams {
          preTestUrl
          postTestUrl
          evaluationUrl
        }
        type
      }
    }
  }
`;

/**
 * __useGetAllWebinarsQuery__
 *
 * To run a query within a React component, call `useGetAllWebinarsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWebinarsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWebinarsQuery({
 *   variables: {
 *      getWebinarsQuery: // value for 'getWebinarsQuery'
 *   },
 * });
 */
export function useGetAllWebinarsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllWebinarsQuery,
    GetAllWebinarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllWebinarsQuery, GetAllWebinarsQueryVariables>(
    GetAllWebinarsDocument,
    options,
  );
}
export function useGetAllWebinarsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllWebinarsQuery,
    GetAllWebinarsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllWebinarsQuery, GetAllWebinarsQueryVariables>(
    GetAllWebinarsDocument,
    options,
  );
}
export type GetAllWebinarsQueryHookResult = ReturnType<
  typeof useGetAllWebinarsQuery
>;
export type GetAllWebinarsLazyQueryHookResult = ReturnType<
  typeof useGetAllWebinarsLazyQuery
>;
export type GetAllWebinarsQueryResult = Apollo.QueryResult<
  GetAllWebinarsQuery,
  GetAllWebinarsQueryVariables
>;
export const GetWebinarDocument = gql`
  query GetWebinar($getWebinarId: ID!, $getIsWebinar: OptionEnum) {
    getWebinar(id: $getWebinarId, isWebinar: $getIsWebinar) {
      id
      topic
      description
      startUrl
      joinUrl
      passcode
      startTime
      speakerName
      platform
      duration
      status
      registrationStatus
      createdAt
      createdBy
      isRegistered
      facebookStreamUrl
      teamsMeetingUrl
      resourcesUrl
      certificateBase64
      zoomMeetingId
      zoomMeetingLink
      industry
      isAllowRegistration
      isShared
      isWebinar
      type
      exams {
        preTestUrl
        postTestUrl
        evaluationUrl
      }
      registrations {
        id
        name
        email
        organization
      }
    }
  }
`;

/**
 * __useGetWebinarQuery__
 *
 * To run a query within a React component, call `useGetWebinarQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWebinarQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWebinarQuery({
 *   variables: {
 *      getWebinarId: // value for 'getWebinarId'
 *      getIsWebinar: // value for 'getIsWebinar'
 *   },
 * });
 */
export function useGetWebinarQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWebinarQuery,
    GetWebinarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWebinarQuery, GetWebinarQueryVariables>(
    GetWebinarDocument,
    options,
  );
}
export function useGetWebinarLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWebinarQuery,
    GetWebinarQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWebinarQuery, GetWebinarQueryVariables>(
    GetWebinarDocument,
    options,
  );
}
export type GetWebinarQueryHookResult = ReturnType<typeof useGetWebinarQuery>;
export type GetWebinarLazyQueryHookResult = ReturnType<
  typeof useGetWebinarLazyQuery
>;
export type GetWebinarQueryResult = Apollo.QueryResult<
  GetWebinarQuery,
  GetWebinarQueryVariables
>;
export const ActionWebinarDocument = gql`
  mutation ActionWebinar($actionWebinarInput: ActionWebinarInput) {
    actionWebinar(input: $actionWebinarInput) {
      id
      status
    }
  }
`;
export type ActionWebinarMutationFn = Apollo.MutationFunction<
  ActionWebinarMutation,
  ActionWebinarMutationVariables
>;

/**
 * __useActionWebinarMutation__
 *
 * To run a mutation, you first call `useActionWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActionWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [actionWebinarMutation, { data, loading, error }] = useActionWebinarMutation({
 *   variables: {
 *      actionWebinarInput: // value for 'actionWebinarInput'
 *   },
 * });
 */
export function useActionWebinarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ActionWebinarMutation,
    ActionWebinarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    ActionWebinarMutation,
    ActionWebinarMutationVariables
  >(ActionWebinarDocument, options);
}
export type ActionWebinarMutationHookResult = ReturnType<
  typeof useActionWebinarMutation
>;
export type ActionWebinarMutationResult =
  Apollo.MutationResult<ActionWebinarMutation>;
export type ActionWebinarMutationOptions = Apollo.BaseMutationOptions<
  ActionWebinarMutation,
  ActionWebinarMutationVariables
>;
export const UpdateWebinarDocument = gql`
  mutation UpdateWebinar($updateWebinarInput: UpdateWebinarInput) {
    updateWebinar(input: $updateWebinarInput)
  }
`;
export type UpdateWebinarMutationFn = Apollo.MutationFunction<
  UpdateWebinarMutation,
  UpdateWebinarMutationVariables
>;

/**
 * __useUpdateWebinarMutation__
 *
 * To run a mutation, you first call `useUpdateWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWebinarMutation, { data, loading, error }] = useUpdateWebinarMutation({
 *   variables: {
 *      updateWebinarInput: // value for 'updateWebinarInput'
 *   },
 * });
 */
export function useUpdateWebinarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWebinarMutation,
    UpdateWebinarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWebinarMutation,
    UpdateWebinarMutationVariables
  >(UpdateWebinarDocument, options);
}
export type UpdateWebinarMutationHookResult = ReturnType<
  typeof useUpdateWebinarMutation
>;
export type UpdateWebinarMutationResult =
  Apollo.MutationResult<UpdateWebinarMutation>;
export type UpdateWebinarMutationOptions = Apollo.BaseMutationOptions<
  UpdateWebinarMutation,
  UpdateWebinarMutationVariables
>;
export const AddWebinarDocument = gql`
  mutation AddWebinar($addWebinarInput: AddWebinarInput) {
    addWebinar(input: $addWebinarInput)
  }
`;
export type AddWebinarMutationFn = Apollo.MutationFunction<
  AddWebinarMutation,
  AddWebinarMutationVariables
>;

/**
 * __useAddWebinarMutation__
 *
 * To run a mutation, you first call `useAddWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWebinarMutation, { data, loading, error }] = useAddWebinarMutation({
 *   variables: {
 *      addWebinarInput: // value for 'addWebinarInput'
 *   },
 * });
 */
export function useAddWebinarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddWebinarMutation,
    AddWebinarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddWebinarMutation, AddWebinarMutationVariables>(
    AddWebinarDocument,
    options,
  );
}
export type AddWebinarMutationHookResult = ReturnType<
  typeof useAddWebinarMutation
>;
export type AddWebinarMutationResult =
  Apollo.MutationResult<AddWebinarMutation>;
export type AddWebinarMutationOptions = Apollo.BaseMutationOptions<
  AddWebinarMutation,
  AddWebinarMutationVariables
>;
export const UpdateUserRoleMutationDocument = gql`
  mutation UpdateUserRoleMutation($updateUserRoleInput: UpdateUserRoleInput!) {
    updateUserRole(input: $updateUserRoleInput)
  }
`;
export type UpdateUserRoleMutationMutationFn = Apollo.MutationFunction<
  UpdateUserRoleMutationMutation,
  UpdateUserRoleMutationMutationVariables
>;

/**
 * __useUpdateUserRoleMutationMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutationMutation, { data, loading, error }] = useUpdateUserRoleMutationMutation({
 *   variables: {
 *      updateUserRoleInput: // value for 'updateUserRoleInput'
 *   },
 * });
 */
export function useUpdateUserRoleMutationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserRoleMutationMutation,
    UpdateUserRoleMutationMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateUserRoleMutationMutation,
    UpdateUserRoleMutationMutationVariables
  >(UpdateUserRoleMutationDocument, options);
}
export type UpdateUserRoleMutationMutationHookResult = ReturnType<
  typeof useUpdateUserRoleMutationMutation
>;
export type UpdateUserRoleMutationMutationResult =
  Apollo.MutationResult<UpdateUserRoleMutationMutation>;
export type UpdateUserRoleMutationMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserRoleMutationMutation,
  UpdateUserRoleMutationMutationVariables
>;
export const DeleteWebinarDocument = gql`
  mutation DeleteWebinar($deleteWebinarId: ID!) {
    deleteWebinar(id: $deleteWebinarId)
  }
`;
export type DeleteWebinarMutationFn = Apollo.MutationFunction<
  DeleteWebinarMutation,
  DeleteWebinarMutationVariables
>;

/**
 * __useDeleteWebinarMutation__
 *
 * To run a mutation, you first call `useDeleteWebinarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWebinarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWebinarMutation, { data, loading, error }] = useDeleteWebinarMutation({
 *   variables: {
 *      deleteWebinarId: // value for 'deleteWebinarId'
 *   },
 * });
 */
export function useDeleteWebinarMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteWebinarMutation,
    DeleteWebinarMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteWebinarMutation,
    DeleteWebinarMutationVariables
  >(DeleteWebinarDocument, options);
}
export type DeleteWebinarMutationHookResult = ReturnType<
  typeof useDeleteWebinarMutation
>;
export type DeleteWebinarMutationResult =
  Apollo.MutationResult<DeleteWebinarMutation>;
export type DeleteWebinarMutationOptions = Apollo.BaseMutationOptions<
  DeleteWebinarMutation,
  DeleteWebinarMutationVariables
>;
