import { useState } from 'react';
import firebase from 'gatsby-plugin-firebase';
import { AuthClaim, AuthVendor, FirebaseUser } from '../interfaces/Props';
import { FirebaseEnum, FirebaseMessage } from '../enums/FirebaseEnum';
import { navigate } from 'gatsby';
import { OptionEnum, UserRoleEnum } from '../graphql/__types';
import { useLocation } from '@reach/router';
import {
  destroyStorage,
  getToken,
  setAuthLoading,
  setToken,
  setUser,
} from '../services/auth';

export const useAuth = () => {
  const { search } = useLocation();

  const [isAuthenticating, setIsAuthenticating] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const parseUser = (user: firebase.auth.IdTokenResult) => {
    const token = user.token;

    const claims: AuthClaim = {
      id: user.claims.user_id,
      name: user.claims.name,
      email: user.claims.email,
      exp: user.claims.exp,
      role: !user.claims.role ? UserRoleEnum.User : user.claims.role,
      picture: user.claims.picture,
      isCheckpoint:
        typeof user.claims.isCheckpoint === 'undefined'
          ? true
          : user.claims.isCheckpoint,
    };

    setToken(token);
    setUser(claims);

    return claims;
  };

  const checkUser = () => {
    firebase.auth().onAuthStateChanged(async (user: FirebaseUser | null) => {
      if (user !== null) {
        const firebaseUser = await user.getIdTokenResult();
        const storedToken = getToken();

        if (firebaseUser.token !== storedToken) {
          parseUser(firebaseUser);
        }
      }
    });
  };

  const initializeAuth = () => {
    setIsAuthenticating(true);
    setIsError(false);
    setErrorMessage(null);
    setAuthLoading('init');
  };

  const closeAuth = () => {
    setIsAuthenticating(false);
  };

  const getIfExhibit = () => {
    const query = new URLSearchParams(search);
    return query.get('exhibit');
  };

  const getContinueUrl = () => {
    const query = new URLSearchParams(search);
    return query.get('continue');
  };

  const getIsWebinar = (): OptionEnum | null => {
    const query = new URLSearchParams(search);
    return query.get('isWebinar') as OptionEnum;
  };

  const redirectAfterAuth = () => {
    const continueUrl = getContinueUrl();
    const isWebinar = getIsWebinar();
    const isExhibit = getIfExhibit();

    if (isExhibit) return navigate(`/exhibit`);

    if (continueUrl !== null && isWebinar !== null) {
      return navigate(
        `/${
          isWebinar === OptionEnum.Yes ? 'webinars' : 'activities'
        }${continueUrl}`,
        { replace: true },
      );
    }

    navigate('/', { replace: true });
  };

  const authenticate = async (provider: AuthVendor) => {
    initializeAuth();

    let authProvider = null;
    if (provider === 'GO') authProvider = authWithGoogle();
    if (provider === 'FB') authProvider = authWithFacebook();
    if (provider === 'MS') authProvider = authWithMicrosoft();
    if (provider === 'YH') authProvider = authWithYahoo();
    if (authProvider !== null) {
      try {
        await firebase.auth().signInWithRedirect(authProvider);
      } catch (error) {
        console.error(error);
        handleErrorMessage(error);
      } finally {
        return closeAuth();
      }
    }

    closeAuth();
  };

  const signOut = async () => {
    try {
      await firebase.auth().signOut();
      destroyStorage();
    } catch (error) {
      console.error(error);
    }
  };

  const handleErrorMessage = (error: any) => {
    setIsError(true);

    if (typeof error === 'string') {
      setErrorMessage(error);
    }
    if (typeof error !== 'string') {
      if (error.code === FirebaseEnum.AccountExist) {
        setErrorMessage(FirebaseMessage.AccountExist);
      }
      if (error.code === FirebaseEnum.PopupClosed) {
        setErrorMessage(FirebaseMessage.PopupClosed);
      }
    }
  };

  const authWithGoogle = () => {
    return new firebase.auth.GoogleAuthProvider();
  };

  const authWithFacebook = () => {
    return new firebase.auth.FacebookAuthProvider();
  };

  const authWithMicrosoft = () => {
    const provider = 'microsoft.com';
    const providerTenant = 'd9fa5cee-0cea-4130-8c96-b7dc4eeabfe6';

    const microsoftProvider = new firebase.auth.OAuthProvider(provider);
    microsoftProvider.setCustomParameters({ tenant: providerTenant });

    return microsoftProvider;
  };

  const authWithYahoo = () => {
    return new firebase.auth.OAuthProvider('yahoo.com');
  };

  return {
    checkUser,
    isAuthenticating,
    isError,
    handleErrorMessage,
    errorMessage,
    authenticate,
    signOut,
    redirectAfterAuth,
    getContinueUrl,
    parseUser,
  };
};
