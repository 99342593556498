/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import { useLocation } from '@reach/router';

interface Props {
  title?: string;
}

const SeoHeader = ({ title }: Props) => {
  const { pathname } = useLocation();

  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
            eventTheme
            eventSchedule
            ogImage
            twitterImage
            social {
              twitter
            }
          }
        }
      }
    `,
  );

  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = `${site.siteMetadata.eventTheme}. ${site.siteMetadata.eventSchedule}`;
  const metaUrl =
    pathname === '/'
      ? site.siteMetadata.siteUrl
      : `${site.siteMetadata.siteUrl}${pathname}`;
  const fbAppId = site.siteMetadata.fbAppId;
  const defaultTitle = 'National Science & Technology Week in Region 1';
  const ogImage = `${metaUrl}${site.siteMetadata.ogImage}`;
  const twitterImage = `${metaUrl}${site.siteMetadata.twitterImage}`;
  const meta = [] as any;

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        class: 'bg-white text-gray-500 antialiased',
      }}
      title={metaTitle}
      titleTemplate={defaultTitle ? `%s | ${defaultTitle}` : undefined}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: metaUrl,
        },
        {
          property: `fb:app_id`,
          content: fbAppId,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: ogImage,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata?.social?.twitter || ``,
        },
        {
          name: `twitter:title`,
          content: metaTitle,
        },
        {
          name: 'twitter:image',
          content: twitterImage,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
};

export default SeoHeader;
